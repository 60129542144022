/* App.css */
.App {
  text-align: center;
  background-color: #0c0c0c; /* Dark background typical of old terminals */
  color: #33ff33; /* Classic green terminal text */
  font-family: "Courier New", Courier, monospace; /* Monospace font for that classic look */
}

.App-header {
  background-color: transparent;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Retro effect for headings and links */
h1,
.App-link {
  text-shadow: 0 0 5px rgba(51, 255, 51, 0.8);
}

button {
  background-color: #262626;
  color: #33ff33;
  border: 1px solid #33ff33;
  padding: 8px 16px;
  text-transform: uppercase;
  font-family: "Courier New", Courier, monospace;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

button:hover {
  background-color: #0f0f0f;
}

input {
  background-color: #0c0c0c;
  color: #33ff33;
  border: 1px solid #33ff33;
  margin-bottom: 10px;
  padding: 8px;
}

input::placeholder {
  color: #559955;
}

/* Specific styles for the captcha container */
.captcha-container {
  background-color: #ffffff; /* White background */
  display: inline-block; /* Wraps the size around the content */
  padding: 10px; /* Some padding around the captcha */
  border-radius: 5px; /* Rounded corners for aesthetics */
  margin-top: 20px; /* Space above the captcha */
}

.captcha-container svg {
  width: 100%; /* Ensures the SVG fills the container appropriately */
  height: auto; /* Maintains aspect ratio */
}
